import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, InsetSection } from '~styles/global'
import DataViewer from '~utils/DataViewer'
import { graphql } from 'gatsby'
import { useSiteState } from '~context/siteContext'
import PageHeader from '~components/PageHeader'
import Section from '~components/Section'
import RichText from '~components/RichText'
import SanityLink from '~components/SanityLink'
import MarqueeSlider from '~components/MarqueeSlider'
import Seo from '~components/Seo'
import VimeoInline from '~components/VimeoInline'
import { ArrowTopRight } from '../components/Svg'

const Director = ({ data }) => {
	const { inlineVideo, ...page } = data?.sanityDirector
	const [siteState, setSiteState] = useSiteState()

	useEffect(() => {
		setSiteState((prevState) => ({
			...prevState,
			pageTitle: page.title,
		}))
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<Seo title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.metaDescription} image={page.seo?.socialImage} />
			<Wrap>
				<Section>
					<StyledPageHeader content={page?.headerMedia} relativeHeight />
				</Section>
				<MainSection>
					<Col>
						<Text>
							{page.introText && <RichText content={page.introText} />}
						</Text>
					</Col>
					<Col>
						{page.lists.map((list) => (
							<LineList key={list.title} className="sans">
								<LineListTitle>{list.title}</LineListTitle>
								{list.items.map((item) => (
									<LineListItem key={item.title} link={item.link}>
										{item.title}
										{item.link?.linkType === 'external' && <Arrow />}
									</LineListItem>
								))}
							</LineList>
						))}
					</Col>
				</MainSection>
				{inlineVideo &&
					<VimeoInline
						className={'videoFull'}
						videoId={inlineVideo.vimeoUrl?.oEmbedData?.video_id}
						showControls={inlineVideo.showControls || true}
						aspectRatio={inlineVideo.vimeoUrl?.oEmbedData?.width / inlineVideo.vimeoUrl?.oEmbedData?.height}
					/>
				}
				<MarqueeSlider slides={page.mediaSlider?.slides} />
				<DataViewer data={page} name="page" />
			</Wrap>
		</>
	)
}

const Wrap = styled.div`
	background-color: var(--black);
	color: var(--white);
`
const StyledPageHeader = styled(PageHeader)`
	grid-column: span 12;
	margin-top: 94px;
	margin-bottom: 72px;
	${mobile} {
		margin-bottom: 0;
		margin-top: 0;
	}
`
const MainSection = styled(InsetSection)`
	margin-bottom: 91px;
	> div {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 60px;
	}
	${tablet} {
		margin-bottom: 100px;
	}
`
const Col = styled.div`
	grid-column: span 1;
	${mobile} {
		grid-column: span 2;
	}
`

const Text = styled.div`
	${mobile} {
		margin-bottom: 52px;
	}
`
const LineList = styled.div`
	color: var(--white);
	margin-bottom: 66px;
	${mobile} {
		margin-bottom: 44px;
	}
`
const LineListTitle = styled.div`
	padding-bottom: 4px;
	border-bottom: 1px solid var(--white);
	font-size: var(--s);
`
const LineListItem = styled(SanityLink)`
	display: block;
	padding-bottom: 4px;
	padding-top: 3px;
	border-bottom: 1px solid var(--white);
	font-size: var(--s);
`

const Arrow = styled(ArrowTopRight)`
	position: relative;
	top: 5px;
	height: 24px;
	width: 24px;
	margin-left: 0.15rem;
	> path {
		fill: var(--white);
	}
`

Director.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
	query DirectorQuery($slug: String) {
		sanityDirector(slug: {current: {eq: $slug}}) {
			title
			slug {
				current
			}
			seo {
				...seo
			}
			headerMedia {
				...headerMedia
			}
			introText: _rawIntroText(resolveReferences: {maxDepth: 5})
			inlineVideo {
				_type
				_key
				vimeoUrl {
					oEmbedData {
						video_id
						width
						height
					}
				}
				showControls
			}
			lists {
				title
				items {
					title
					link {
						...link
					}
				}
			}
			mediaSlider {
				...mediaSlider
			}
		}
	}
`

export default Director
